.profile-layout {
    padding: 4%;
    width: 100%;
}

.profile-image {
    padding: 5px;
}

.profile-title {
    padding: 5px;
}

.profile-description {
    padding: 5px
}