.itemsList {
    width: 100%;
    padding: 1%;
}
.itemsListItem{
    list-style-type: none;
    min-height: 400px;
    max-height: 500px;
    width: 23%;
    margin: 1%;
    float: left;
    border-radius: 5px;
}

.pdfView {
    width: 60%;
    height: 100vh;
    margin: auto;
}
iframe::-webkit-scrollbar {  
    display: none;
  }

.itemDownloadBtn {
    color: white;
    border: 2px solid #0d6efd;
    border-radius: 5px;
    padding: 1%;
    background-color: #0d6efd;
    width: 30%;
    margin: 4% 35%;
}

.itemsListImageContainer .pdfImage {
    height: 200px;
}

.itemsListImageContainer {
    width: 100%;
    background-color: rgba(255,255,255,0.6);
}
.itemsListImage {
    list-style-type: none;
    width: 100%;
    max-height: 100px;
    margin: 1%;
    float: left;
    border-radius: 5px;
}

.addResourceBtn {
    border: 2px solid;
    border-radius: 5px;
    width: fit-content;
    padding: 5px;
    text-decoration: none;
    /* margin-left: 70%; */
    margin: 5px;
}

.itemsListContainer {
    padding: 1%;
    word-wrap: break-word;
}
.itemsListContainer h2 {
    padding: 0%;
    margin: 0%;
    font-size: 1rem;
}
.itemsListContainer p {
    padding: 0%;
    margin: 0%;
}

.itemsListLink {
    text-decoration: none;
    color: black;
}


.pdfEmbed {
    display: none;
}
.pdfThumb {
    display: block;
    height: fit-content;
}
   
@media screen and (max-width: 700px) {
    .itemsListItem{
        width: 31%;
        min-height: 300px;
    }
    

    .itemsListImage {
        max-height: 90px;
    }

    .itemDownloadBtn {
        width: 40%;
        margin: 4% 30%;
    }
    .pdfView {
        width: 90%;
        height: 100vh;
    }

    .pdfEmbed {
        display: block;
    }
    .pdfThumb {
        display: none;
    }
    
    .itemsListImageContainer{
        background-color: none;
    }
}
   
@media screen and (max-width: 400px) {
    .itemsListItem{
        width: 48%;
    }
    .itemsListImage {
        max-height: 90px;
    }
}