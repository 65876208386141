.loading-wheel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .spinner {
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  