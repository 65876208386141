*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

.page-layout__content, .main-nav {
    font-family: 'Montserrat', sans-serif !important;
}

.dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
 }

#root{
    height: 100%;
    width: 100%;
    max-width: 1500px;
    margin: auto;
}

body {
    background-color: whitesmoke;
}

body, html {
    height: 100%;
}

.dashContent {
    margin: 5%
}

.container {
    padding: 2%;
}

.containerMain {
    display: flex;
}

.container-flex {
    display: flex;
    flex-direction: column;
}

.overflow-x {
    width: 100%;
    overflow-x: scroll;
    display: block;
}

.button-danger {
    background-color: red;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    margin: 2% auto;
    text-decoration: none;
}

.column-3 {
    float: left;
    width: 31%;
    margin: 1%;
 }

 .fa {
    padding: 16px;
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
  }
  

  .fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}


.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-youtube {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}


.row-3:after {
    content: "";
    display: table;
    clear: both;
 }

.info-box {
    padding: 2% 2%;
    margin: 1%;
    background-color: grey;
    color: white;
    border-radius: 5px;
    width: fit-content;
}

.pdfContainer {
  width: 100% !important; /* Ensure it uses the full width of its parent */
  height: 0 !important; /* Set to 0 to use padding for aspect ratio */
  padding-top: 56.25% !important; /* 16:9 aspect ratio. Adjust if needed */
  position: relative !important; 
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important; 
}


 @media screen and (max-width: 700px) {
    .column-3 {
        width: 100%;
    }
    .info-box {
        width: 90%;
        margin: auto;
    }
    #contactForm {
        padding: 4%;
    }

 }