.topicsList {
 width: 100%;
 padding: 1%;
 min-height: 50vh;
}
.topicsListItem{
    list-style-type: none;
    min-height: 220px;
    max-height: 220px;
    width: 23%;
    margin: 1%;
    float: left;
    background: round;
    padding: 2%;
}

.topicsListItem h2 {
    font-size: 1rem;
}

.topicsListItem p {
    line-height: 100%;
    font-size: 0.8rem;
    color: rgb(49, 151, 240);
}

.topicsListLink {
    text-decoration: none;
    color: black;
}

.topicsListImage {
    list-style-type: none;
    width: 100%;
    max-height: 100px;
    margin: 1%;
    float: left;
    border-radius: 5px;
}

.topicsListContainer {
    padding: 1%;
    word-wrap: break-word;
}

.topicsList {
    width: 100%;
}

@media screen and (max-width: 700px) {
    .topicsListItem{
        width: 31%;
    }
    .topicsListImage {
        max-height: 90px;
    }
 }

 @media screen and (max-width: 500px) {
    .topicsListItem{
        width: 48%;
    }
    .topicsListImage {
        max-height: 90px;
    }
 }